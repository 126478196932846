import { IconType } from 'react-icons'
import { BiCar } from 'react-icons/bi'
import { GrBike } from 'react-icons/gr'
import { MdCommute } from 'react-icons/md'
import { RiEBike2Line } from 'react-icons/ri'
import { TbScooter } from 'react-icons/tb'

import { VehicleTypesEnum } from './Enums'
import CargoBikeIcon from './icons/CargoBikeIcon'
import DeliveryBotIcon from './icons/DeliveryBotIcon'

export const VEHICLES_CATEGORY_ICON_MAP: Record<string, IconType> = {
  [VehicleTypesEnum.bicycle]: GrBike,
  [VehicleTypesEnum.car]: BiCar,
  [VehicleTypesEnum.cargo_bicycle]: CargoBikeIcon,
  [VehicleTypesEnum.delivery_bot]: DeliveryBotIcon,
  [VehicleTypesEnum.moped]: RiEBike2Line,
  [VehicleTypesEnum.other]: MdCommute,
  [VehicleTypesEnum.scooter]: TbScooter,
}
