import React from 'react'
import { useTranslation } from 'react-i18next'

interface IOriginDestinationPopupContentProps {
  tripCount: number
}

interface IStreetSegmentPopupContentProps {
  id: string
  streetName: string | null
  tripCount: number
}

export function DestinationPopupContent(props: IOriginDestinationPopupContentProps) {
  const { tripCount } = props
  const { t } = useTranslation()

  return (
    <>
      <div>
        <span style={{ fontSize: 13 }}>
          <strong>{`${t('city_page.heatmap.popup.trips_end_number_in_tile')} : `}</strong>
        </span>
        <span style={{ fontSize: 13 }}>{tripCount}</span>
      </div>
    </>
  )
}

export function OriginPopupContent(props: IOriginDestinationPopupContentProps) {
  const { tripCount } = props
  const { t } = useTranslation()

  return (
    <>
      <div>
        <span style={{ fontSize: 13 }}>
          <strong>{`${t('city_page.heatmap.popup.trips_start_number_in_tile')} : `}</strong>
        </span>
        <span style={{ fontSize: 13 }}>{tripCount}</span>
      </div>
    </>
  )
}

export function StreetSegmentPopupContent(props: IStreetSegmentPopupContentProps) {
  const { id, tripCount, streetName } = props
  const { t } = useTranslation()

  return (
    <>
      <div>
        <span style={{ fontSize: 13 }}>
          <strong>{`${t('city_page.heatmap.popup.street_name')} : `}</strong>
        </span>
        <span style={{ fontSize: 13 }}>{streetName || t('city_page.heatmap.popup.undefined')}</span>
      </div>
      <div>
        <span style={{ fontSize: 13 }}>
          <strong>{`${t('city_page.heatmap.popup.street_segment_id')} : `}</strong>
        </span>
        <span style={{ fontSize: 13 }}>{id}</span>
      </div>
      <div>
        <span style={{ fontSize: 13 }}>
          <strong>{`${t('city_page.heatmap.popup.trips_number_in_street_segment')} : `}</strong>
        </span>
        <span style={{ fontSize: 13 }}>{tripCount}</span>
      </div>
    </>
  )
}
