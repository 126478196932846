import AnnouncementIcon from '@mui/icons-material/Announcement'
import { Card, CardHeader, CardContent, Typography } from '@mui/material'
import React from 'react'

interface ICityDescriptionProps {
  description: string
}

export const CityDescription = function (props: ICityDescriptionProps) {
  return (
    <Card sx={() => ({ backgroundColor: '#d4e6ff', marginX: '1rem' })}>
      <CardHeader action={<AnnouncementIcon htmlColor={'#3a75c4'} />} sx={{ color: 'deepskyblue', paddingBottom: 0 }} />
      <CardContent sx={{ paddingTop: 0 }}>
        <Typography>{props.description}</Typography>
      </CardContent>
    </Card>
  )
}
