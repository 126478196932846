import SphericalMercator from '@mapbox/sphericalmercator'
import { Typography } from '@mui/material'
import { ParentSize } from '@visx/responsive'
import { Feature, FeatureCollection, MultiLineString, Polygon } from 'geojson'
import _ from 'lodash'
import { Expression, FullscreenControl, Popup } from 'mapbox-gl'
import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'

import { Theme } from 'Theme/theme'

import { Heatmap } from './constants'
import LegendRadioButtons from './LegendRadioButtons'
import MapBoxMap from './MapBoxMap'
import { DestinationPopupContent, OriginPopupContent, StreetSegmentPopupContent } from './PopupContents'
import QuantileBasedLegend, { createQuantileBasedColorScale, IQuantileColorScale } from './QuantileBasedLegend'

import { IViewport } from '.'

const STREET_SEGMENTS_PALETTE = [
  '#84CA50', // light green
  '#F07D02', // orange
  '#E60000', // light red
  '#9E1313', // dark red
]
const STREET_SEGMENTS_LEGEND_QUANTILES = [50, 75, 95]

const ORIGIN_DESTINATION_PALETTE = ['#91B8DF', '#9CA5C3', '#A792A7', '#B27F8B', '#BD6C6F', '#C95953', '#D44637']
const ORIGIN_DESTINATION_QUANTILES = [50, 75, 90, 95, 98, 99.5]

const MAX_ZOOM_LEVEL = 22

enum TripOriginDestination_KindEnum {
  'destination' = 'destination',
  'origin' = 'origin',
}

enum HeatmapLayerId {
  StreetSegments = 'StreetSegments',
  TripDestination = 'TripDestination',
  TripOrigin = 'TripOrigin',
}

export interface ITripsWithOriginsDestinations {
  kind: string
  trip_count: number
  x: number
  y: number
  zoom: number
}

interface IGeoJson {
  coordinates: number[][][]
  type: string
}

interface IStreetSegments {
  geojson: IGeoJson
  name: string
}

export interface ITripCountsInStreetSegments {
  documents: { doc_count: number; key: string }[]
  next_page?: number | null
  street_segments: { [Key: string]: IStreetSegments }
  total_page_num: number
  trips_per_street_max: number
  trips_per_street_min: number
}

type StreetSegmentFeatureProperties = {
  id: string
  streetName: string | null
  tripCount: number
} | null
type StreetSegmentFeature = Feature<MultiLineString, StreetSegmentFeatureProperties>

type OriginDestinationFeatureProperties = {
  tripCount: number
} | null
type OriginDestinationFeature = Feature<Polygon, OriginDestinationFeatureProperties>

function useIsMounted(): MutableRefObject<boolean> {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted
}

function useSafeState<State>(initialState: State | (() => State)): [State, Dispatch<SetStateAction<State>>] {
  const [state, setState] = useState(initialState)

  const isMounted = useIsMounted()

  function safeSetState(newState: SetStateAction<State>): void {
    if (isMounted.current) {
      setState(newState)
    } else {
      console.warn(
        `Warning: Can't call setState on an unmounted component. 
          This is a no-op, but it indicates a memory leak in your application. 
          To fix, cancel all subscriptions and asynchronous tasks in 
          the return function of useEffect().`
      )
    }
  }

  return [state, safeSetState]
}

function removeLayer(map: mapboxgl.Map, layerId: string) {
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId)
  }
  if (map.getSource(layerId)) {
    map.removeSource(layerId)
  }
}

function clearLayers(map: mapboxgl.Map) {
  removeLayer(map, HeatmapLayerId.TripOrigin)
  removeLayer(map, HeatmapLayerId.TripDestination)
  removeLayer(map, HeatmapLayerId.StreetSegments)
}

function mapTripDocumentsToFeatureCollections(
  trips: ITripsWithOriginsDestinations[]
): [FeatureCollection<Polygon>, FeatureCollection<Polygon>] {
  const tripOriginFeatures: Feature<Polygon>[] = []
  const tripDestinationFeatures: Feature<Polygon>[] = []

  const merc = new SphericalMercator({ size: 256 })
  for (const trip of trips) {
    const [lon_min, lat_min] = merc.bbox(trip.x, trip.y, trip.zoom)
    const [lon_max, lat_max] = merc.bbox(trip.x + 1, trip.y + 1, trip.zoom)
    const feature: Feature<Polygon> = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [lon_min, lat_min],
            [lon_min, lat_max],
            [lon_max, lat_max],
            [lon_max, lat_min],
            [lon_min, lat_min],
          ],
        ],
      },
      properties: { tripCount: trip.trip_count },
    }
    if (trip.kind === TripOriginDestination_KindEnum.origin) {
      tripOriginFeatures.push(feature)
    } else {
      tripDestinationFeatures.push(feature)
    }
  }

  return [
    {
      type: 'FeatureCollection',
      features: tripOriginFeatures,
    },
    {
      type: 'FeatureCollection',
      features: tripDestinationFeatures,
    },
  ]
}

function addHeatmapLayerToMap(
  map: mapboxgl.Map,
  layerId: string,
  layerData: FeatureCollection<Polygon>,
  colorScale: IQuantileColorScale | null
) {
  if (layerData.features.length === 0 || !colorScale) {
    return
  }

  const fillColor: Expression = ['step', ['get', 'tripCount'], colorScale.colors[0]]
  for (let i = 1; i < colorScale.colors.length; i++) {
    fillColor.push(colorScale.values[i])
    fillColor.push(colorScale.colors[i])
  }

  map.addSource(layerId, {
    type: 'geojson',
    data: layerData,
  })
  map.addLayer({
    id: layerId,
    source: layerId,
    maxzoom: MAX_ZOOM_LEVEL,
    type: 'fill',
    paint: {
      'fill-color': fillColor,
      'fill-opacity': 0.6,
    },
  })
}

function mapStreetSegmentDocumentsToFeatureCollection(
  tripCountInStreetSegments: ITripCountsInStreetSegments
): FeatureCollection<MultiLineString> {
  const features: StreetSegmentFeature[] = []

  for (const { key, doc_count } of tripCountInStreetSegments.documents) {
    const streetSegment = tripCountInStreetSegments.street_segments[key]

    if (streetSegment === undefined) {
      // this should not happen now that the street segments and the frequencies are bundled
      // in the same request.
      console.warn(
        `Street segment ${key} is in street_segments count aggregation but not in street segments object, 
          so it will not be possible to display it on the map.`
      )
      continue
    }

    features.push({
      type: 'Feature',
      properties: {
        id: key,
        tripCount: doc_count,
        streetName: streetSegment.name,
      },
      geometry: streetSegment.geojson as MultiLineString,
    })
  }
  return {
    type: 'FeatureCollection',
    features,
  }
}

function addStreetSegmentsToMap(
  map: mapboxgl.Map,
  tripCountInStreetSegments: ITripCountsInStreetSegments,
  tripHeatmapColorScale: IQuantileColorScale | null
): void {
  if (!tripCountInStreetSegments.documents.length || !tripHeatmapColorScale) {
    return
  }

  map.addSource(HeatmapLayerId.StreetSegments, {
    type: 'geojson',
    data: mapStreetSegmentDocumentsToFeatureCollection(tripCountInStreetSegments),
  })

  const lineColor: Expression = ['step', ['get', 'tripCount'], tripHeatmapColorScale.colors[0]]
  for (let i = 1; i < tripHeatmapColorScale.colors.length; i++) {
    lineColor.push(tripHeatmapColorScale.values[i])
    lineColor.push(tripHeatmapColorScale.colors[i])
  }

  map.addLayer({
    id: HeatmapLayerId.StreetSegments,
    source: HeatmapLayerId.StreetSegments,
    maxzoom: MAX_ZOOM_LEVEL,
    type: 'line',
    paint: {
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        1, // line-width = 1 when zoom level is 10
        MAX_ZOOM_LEVEL,
        16, // line-width = 16 when zoom level is 22
      ],
      'line-color': lineColor,
      'line-opacity': 0.8,
    },
  })
}

function addLayersToMap(
  map: mapboxgl.Map,
  trips: ITripsWithOriginsDestinations[],
  tripCountInStreetSegments: ITripCountsInStreetSegments,
  tripOriginColorScale: IQuantileColorScale | null,
  tripDestinationColorScale: IQuantileColorScale | null,
  tripStreetSegmentColorScale: IQuantileColorScale | null
): void {
  clearLayers(map)

  const [tripOriginFeatureCollection, tripDestinationFeatureCollection] = mapTripDocumentsToFeatureCollections(trips)

  addHeatmapLayerToMap(map, HeatmapLayerId.TripOrigin, tripOriginFeatureCollection, tripOriginColorScale)
  addHeatmapLayerToMap(map, HeatmapLayerId.TripDestination, tripDestinationFeatureCollection, tripDestinationColorScale)

  addStreetSegmentsToMap(map, tripCountInStreetSegments, tripStreetSegmentColorScale)

  // When all loading operations are done, the map enters an idle state
  map.on('idle', () => void 0)
}

function showLayer(map: mapboxgl.Map, id: HeatmapLayerId) {
  if (map.getLayer(id)) {
    map.setLayoutProperty(id, 'visibility', 'visible')
  }
}

function hideLayer(map: mapboxgl.Map, id: HeatmapLayerId) {
  if (map.getLayer(id)) {
    map.setLayoutProperty(id, 'visibility', 'none')
  }
}

function displayOnlyLayerCorrespondingToHeatmap(map: mapboxgl.Map, heatmap: Heatmap) {
  switch (heatmap) {
    case Heatmap.Origin:
    default:
      showLayer(map, HeatmapLayerId.TripOrigin)
      hideLayer(map, HeatmapLayerId.TripDestination)
      hideLayer(map, HeatmapLayerId.StreetSegments)
      break
    case Heatmap.Destination:
      showLayer(map, HeatmapLayerId.TripDestination)
      hideLayer(map, HeatmapLayerId.TripOrigin)
      hideLayer(map, HeatmapLayerId.StreetSegments)
      break
    case Heatmap.Trips:
      showLayer(map, HeatmapLayerId.StreetSegments)
      hideLayer(map, HeatmapLayerId.TripOrigin)
      hideLayer(map, HeatmapLayerId.TripDestination)
      break
  }
}

function setupStreetSegmentPopup(map: mapboxgl.Map) {
  const popup = new Popup({
    closeButton: false,
    closeOnClick: false,
    anchor: 'left',
    offset: 50,
  })
  map.on('mouseenter', HeatmapLayerId.StreetSegments, (event: mapboxgl.MapLayerMouseEvent) => {
    map.getCanvas().style.cursor = 'pointer'

    // Populate the popup and set its coordinates based on the cursor position
    const features = event.features as StreetSegmentFeature[]
    const { ...props } = features[0].properties
    const reactElement = React.createElement(StreetSegmentPopupContent, props)
    const html = ReactDOMServer.renderToStaticMarkup(reactElement)
    popup.setHTML(html).setLngLat(event.lngLat).addTo(map)
  })
  map.on('mouseleave', HeatmapLayerId.StreetSegments, () => {
    map.getCanvas().style.cursor = ''
    popup.remove()
  })
  map.on('mousemove', HeatmapLayerId.TripOrigin, (event: mapboxgl.MapLayerMouseEvent) => {
    map.getCanvas().style.cursor = 'pointer'
    const features = map.queryRenderedFeatures(event.point) as OriginDestinationFeature[]
    const { ...props } = features[0].properties
    const reactElement = React.createElement(OriginPopupContent, props)
    const html = ReactDOMServer.renderToStaticMarkup(reactElement)
    popup.setHTML(html).setLngLat(event.lngLat).addTo(map)
  })
  map.on('mouseleave', HeatmapLayerId.TripOrigin, () => {
    map.getCanvas().style.cursor = ''
    popup.remove()
  })
  map.on('mousemove', HeatmapLayerId.TripDestination, (event: mapboxgl.MapLayerMouseEvent) => {
    map.getCanvas().style.cursor = 'pointer'
    const features = map.queryRenderedFeatures(event.point) as OriginDestinationFeature[]
    const { ...props } = features[0].properties
    const reactElement = React.createElement(DestinationPopupContent, props)
    const html = ReactDOMServer.renderToStaticMarkup(reactElement)
    popup.setHTML(html).setLngLat(event.lngLat).addTo(map)
  })
  map.on('mouseleave', HeatmapLayerId.TripDestination, () => {
    map.getCanvas().style.cursor = ''
    popup.remove()
  })
}

interface ITripHeatmapProps {
  isLoading: boolean
  tripCountsInStreetSegmentsData?: ITripCountsInStreetSegments
  tripsWithOriginsDestinationsData?: ITripsWithOriginsDestinations[]
  viewport?: IViewport
}

function TripHeatmap(props: ITripHeatmapProps) {
  const { viewport, tripCountsInStreetSegmentsData, tripsWithOriginsDestinationsData, isLoading } = props
  const { t } = useTranslation()

  const [map, setMap] = useSafeState<mapboxgl.Map | undefined>(undefined)
  const [selectedHeatmap, setSelectedHeatmap] = useSafeState<Heatmap>(Heatmap.Origin)

  const [tripOriginColorScale, setTripOriginColorScale] = useSafeState<IQuantileColorScale | null>(null)
  const [tripDestinationColorScale, setTripDestinationColorScale] = useSafeState<IQuantileColorScale | null>(null)
  const [tripStreetSegmentColorScale, setTripStreetSegmentColorScale] = useSafeState<IQuantileColorScale | null>(null)

  function onToggleCheck(heatmap: Heatmap) {
    setSelectedHeatmap(heatmap)
    if (map) {
      displayOnlyLayerCorrespondingToHeatmap(map, heatmap)
    }
  }

  useEffect(() => {
    if (isLoading || !map) {
      return
    }

    map.addControl(new FullscreenControl(), 'bottom-right')
    setupStreetSegmentPopup(map)
  }, [isLoading, map])

  useEffect(() => {
    if (!map || isLoading) {
      return
    }

    let tripOriginColorScale = null
    let tripDestinationColorScale = null
    let tripStreetSegmentColorScale = null

    if (tripsWithOriginsDestinationsData?.length) {
      tripOriginColorScale = createQuantileBasedColorScale(
        tripsWithOriginsDestinationsData
          .filter((trip) => trip.kind === TripOriginDestination_KindEnum.origin)
          .map((trip) => trip.trip_count),
        ORIGIN_DESTINATION_PALETTE,
        ORIGIN_DESTINATION_QUANTILES
      )
      tripDestinationColorScale = createQuantileBasedColorScale(
        tripsWithOriginsDestinationsData
          .filter((trip) => trip.kind === TripOriginDestination_KindEnum.destination)
          .map((trip) => trip.trip_count),
        ORIGIN_DESTINATION_PALETTE,
        ORIGIN_DESTINATION_QUANTILES
      )
    }
    if (tripCountsInStreetSegmentsData?.documents.length) {
      // warning message if street_segments > 100 000
      console.info(`loaded ${Object.keys(tripCountsInStreetSegmentsData.street_segments).length} street_segments`)
      tripStreetSegmentColorScale = createQuantileBasedColorScale(
        _.map(tripCountsInStreetSegmentsData.documents, 'doc_count'),
        STREET_SEGMENTS_PALETTE,
        STREET_SEGMENTS_LEGEND_QUANTILES
      )
    }

    setTripOriginColorScale(tripOriginColorScale)
    setTripDestinationColorScale(tripDestinationColorScale)
    setTripStreetSegmentColorScale(tripStreetSegmentColorScale)

    if (tripsWithOriginsDestinationsData && tripCountsInStreetSegmentsData) {
      addLayersToMap(
        map,
        tripsWithOriginsDestinationsData,
        tripCountsInStreetSegmentsData,
        tripOriginColorScale,
        tripDestinationColorScale,
        tripStreetSegmentColorScale
      )
    }
    displayOnlyLayerCorrespondingToHeatmap(map, selectedHeatmap)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isLoading, tripCountsInStreetSegmentsData, tripsWithOriginsDestinationsData])

  return (
    <>
      <Typography textAlign="center" variant="h2" sx={(theme: Theme) => ({ color: theme.palette.primary.main })}>
        {t('city_page.heat_map')}
      </Typography>
      <ParentSize>
        {({ width }) => {
          return (
            <MapBoxMap
              width={width}
              height={(width * 3) / 4 >= 600 ? (width * 3) / 4 : 600}
              options={{
                logoPosition: 'top-right',
              }}
              onMapReady={(m: mapboxgl.Map) => setMap(m)}
              showSatellite={false}
              showScale="bottom-right"
              showSearchBar="top-left"
              viewport={viewport}
            >
              <div
                style={{
                  borderRadius: 4,
                  display: 'flex',
                  flexDirection: width > 850 ? 'row' : 'column',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  height: width > 850 ? 120 : 200,
                  padding: 24,
                  position: 'absolute',
                  bottom: 20,
                  left: 20,
                  zIndex: 1,
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.18)',
                }}
              >
                <>
                  <LegendRadioButtons selectedHeatmap={selectedHeatmap} onToggleCheck={onToggleCheck} />
                  <div
                    style={{
                      margin: '0 24px',
                      minWidth: width > 850 ? 2 : 30,
                      height: width > 850 ? 30 : 2,
                      backgroundColor: '#9E9E9E',
                    }}
                  />
                </>
                <div style={{ width: 340 }}>
                  {selectedHeatmap === Heatmap.Origin && (
                    <QuantileBasedLegend
                      noDataText={t('city_page.heatmap.no_legend')}
                      quantilesText={t('city_page.heatmap.quantiles_text')}
                      valuesText={t('city_page.heatmap.origin_values_text')}
                      colorScale={tripOriginColorScale}
                    />
                  )}
                  {selectedHeatmap === Heatmap.Destination && (
                    <QuantileBasedLegend
                      noDataText={t('city_page.heatmap.no_legend')}
                      quantilesText={t('city_page.heatmap.quantiles_text')}
                      valuesText={t('city_page.heatmap.destination_values_text')}
                      colorScale={tripDestinationColorScale}
                    />
                  )}
                  {selectedHeatmap === Heatmap.Trips && (
                    <QuantileBasedLegend
                      noDataText={t('city_page.heatmap.no_legend')}
                      quantilesText={t('city_page.heatmap.quantiles_text')}
                      valuesText={t('city_page.heatmap.street_segments_values_text')}
                      colorScale={tripStreetSegmentColorScale}
                    />
                  )}
                </div>
              </div>
            </MapBoxMap>
          )
        }}
      </ParentSize>
    </>
  )
}

export default TripHeatmap
