import _ from 'lodash'
import React, { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react'

import { IBaseFilterProps } from './BaseFilter'

export type IBaseFilterWrapperProps = IBaseFilterProps

export interface IBaseFiterCtxType extends IBaseFilterProps {
  expanded: boolean
  filterId: string
  selectAll: () => void
  setExpanded: (status: boolean) => void
  setValues: Dispatch<SetStateAction<unknown[]>>
  toggle: () => void
  unselectAll: () => void
}

const BaseFilterCtx = createContext<IBaseFiterCtxType | undefined>(undefined)

export function useBaseFilterCtx() {
  const ctx = useContext(BaseFilterCtx)

  if (!ctx) {
    throw new Error('useBaseFilterCtx have to be inside BaseFilterCtx wrapper')
  }

  return ctx
}

export default function BaseFilterWrapper({
  children,
  title,
  subtitle,
  filterId,
  items,
  initialValues = [],
  values: defaultValues = [],
}: PropsWithChildren<IBaseFilterWrapperProps>) {
  const [expanded, setExpanded] = useState(false)
  const [values, setValues] = useState<unknown[]>(defaultValues)

  function handleToggle() {
    setExpanded((exp) => !exp)
  }

  function handleSelectAll() {
    setValues((items ?? []).map((i) => i.id))
  }

  function handleUnselectAll() {
    setValues([])
  }

  function handleReset() {
    setValues(initialValues)
  }

  function getContext() {
    return {
      title,
      items,
      values,
      setValues,
      subtitle,
      filterId,
      expanded,
      setExpanded,
      reset: handleReset,
      toggle: handleToggle,
      selectAll: handleSelectAll,
      unselectAll: handleUnselectAll,
    }
  }

  return <BaseFilterCtx.Provider value={getContext()}>{children}</BaseFilterCtx.Provider>
}
