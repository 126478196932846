import _ from 'lodash'
import React from 'react'

import BaseFilter from '../BaseFilter/BaseFilter'
import CollapsibleFilter from '../BaseFilter/CollapsibleFilter'

import CheckboxFilterWrapper, { ICheckboxFilterWrapperProps } from './CheckboxListFilter.hooks'
import CheckboxListFilterItem from './CheckboxListFilterItem'

export interface ICheckboxesListFilterProps extends ICheckboxFilterWrapperProps {
  onFilterChange?: (filterId: string, values: string[]) => void
  values?: string[]
}

export default function CheckboxListFilter(props: ICheckboxesListFilterProps) {
  return (
    <BaseFilter {...props}>
      <CheckboxFilterWrapper {...props}>
        <CollapsibleFilter displayCheckbox>
          {props?.items?.map((item) => <CheckboxListFilterItem key={item.id} {...item} />)}
        </CollapsibleFilter>
      </CheckboxFilterWrapper>
    </BaseFilter>
  )
}
