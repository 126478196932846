import { useParams } from 'react-router-dom'

export const useCityName = function () {
  const params = useParams()
  const cityName = params.cityName

  if (!cityName) {
    throw new Error('Missing cityName URL parameter')
  }

  return cityName
}
