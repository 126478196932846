import { useMemo } from 'react'

import { VehicleTypesEnum } from 'commons/Enums'
import { ICityData, IVehicleData } from 'Components/FetchData'
import TripHeatmap, { ITripCountsInStreetSegments, ITripsWithOriginsDestinations } from 'Components/MapBox/TripHeatmap'
import TemporalHistogram from 'Components/TemporalHistogram'

import { CityMetrics } from './CityMetrics'

type SelectedVehiclesTypesData = [keyof ICityData, IVehicleData][]
export const useSelectedVehicleData = function (
  selectedVehicleTypes: Set<keyof ICityData>,
  cityData: ICityData | undefined,
  isLoading: boolean,
  error: unknown | null
) {
  const selectedVehicleData = useMemo<SelectedVehiclesTypesData>(() => {
    if (!isLoading && !error && cityData) {
      // We can't properly type Object.entries as it only accepts a template type for the value
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return Object.entries<IVehicleData>(cityData as any).filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([vehicleType]) => selectedVehicleTypes.size === 0 || selectedVehicleTypes.has(vehicleType as any)
      ) as SelectedVehiclesTypesData
    } else {
      return []
    }
  }, [cityData, error, isLoading, selectedVehicleTypes])

  return selectedVehicleData
}

export const useSelectedVehiclesTypesMetrics = function (selectedVehicleData: SelectedVehiclesTypesData) {
  const metrics = useMemo<React.ComponentProps<typeof CityMetrics>['metrics']>(() => {
    const calculatedMetrics = {
      average_number_of_trips_per_day: 0,
      average_trips_distance: 0,
      average_trips_duration: 0,
      from: 0,
      max_vehicles_deployed: 0,
      to: 0,
      total_distance: 0,
      total_trips: 0,
    }

    for (const [, vehicleData] of selectedVehicleData) {
      for (const [metricName, metricValue] of Object.entries(vehicleData['metrics'])) {
        if (metricName.includes('average')) {
          // We need to skip them for now because we first need
          // the total_trips and total_distance to be computed first
          continue
        }
        if (!['to', 'from'].includes(metricName)) {
          calculatedMetrics[metricName as keyof IVehicleData['metrics']] += metricValue
        } else {
          calculatedMetrics[metricName as keyof IVehicleData['metrics']] = metricValue
        }
      }
    }

    // Weighted average of averages
    for (const [, vehicleData] of selectedVehicleData) {
      calculatedMetrics.average_number_of_trips_per_day +=
        (vehicleData.metrics.average_number_of_trips_per_day * vehicleData.metrics.total_trips) /
        calculatedMetrics.total_trips

      calculatedMetrics.average_trips_distance +=
        (vehicleData.metrics.average_trips_distance * vehicleData.metrics.total_distance) /
        calculatedMetrics.total_distance

      calculatedMetrics.average_trips_duration +=
        (vehicleData.metrics.average_trips_duration * vehicleData.metrics.total_trips) / calculatedMetrics.total_trips
    }

    return calculatedMetrics
  }, [selectedVehicleData])

  return metrics
}

export const useSelectedVehiclesTypesRatioUsage = function (selectedVehicleData: SelectedVehiclesTypesData) {
  const ratioUsage = useMemo<React.ComponentProps<typeof TemporalHistogram>['rawData']>(() => {
    const calculatedRatioUsage: Record<string, number> = {}

    for (const [, vehicleData] of selectedVehicleData) {
      for (const { key, doc_count } of vehicleData['ratioUsage']) {
        if (!(key in calculatedRatioUsage)) {
          calculatedRatioUsage[key] = doc_count
        } else {
          calculatedRatioUsage[key] += doc_count
        }
      }
    }

    return Object.entries(calculatedRatioUsage).map(([key, doc_count]) => ({ key, doc_count }))
  }, [selectedVehicleData])

  return ratioUsage
}

export const useSelectedVehiclesTypesNumberOfTrips = function (selectedVehicleData: SelectedVehiclesTypesData) {
  const numberOfTrips = useMemo<React.ComponentProps<typeof TemporalHistogram>['rawData']>(() => {
    const calculatednumberOfTrips: Record<string, number> = {}

    for (const [, vehicleData] of selectedVehicleData) {
      for (const { key, doc_count } of vehicleData['numberOfTrips']) {
        if (!(key in calculatednumberOfTrips)) {
          calculatednumberOfTrips[key] = doc_count
        } else {
          calculatednumberOfTrips[key] += doc_count
        }
      }
    }

    return Object.entries(calculatednumberOfTrips).map(([key, doc_count]) => ({ key, doc_count }))
  }, [selectedVehicleData])

  return numberOfTrips
}

export const useSelectedVehiclesTypesNumberOfVehiclesDeployed = function (
  selectedVehicleData: SelectedVehiclesTypesData
) {
  const numberOfVehiclesDeployed = useMemo<React.ComponentProps<typeof TemporalHistogram>['rawData']>(() => {
    const calculatedNumberOfVehiclesDeployed: Record<string, number> = {}

    for (const [, vehicleData] of selectedVehicleData) {
      for (const { key, stackedBy } of vehicleData['numberOfVehiclesDeployed']) {
        const doc_count = stackedBy.buckets[1].doc_count
        if (!(key in calculatedNumberOfVehiclesDeployed)) {
          calculatedNumberOfVehiclesDeployed[key] = doc_count
        } else {
          calculatedNumberOfVehiclesDeployed[key] += doc_count
        }
      }
    }

    return Object.entries(calculatedNumberOfVehiclesDeployed).map(([key, doc_count]) => ({ key, doc_count }))
  }, [selectedVehicleData])

  return numberOfVehiclesDeployed
}

export const useSelectedVehiclesTypesTripsCountInStreetSegments = function (
  selectedVehicleData: SelectedVehiclesTypesData
) {
  const tripCountsInStreetSegments = useMemo<
    React.ComponentProps<typeof TripHeatmap>['tripCountsInStreetSegmentsData']
  >(() => {
    const calculatedTripCountsInStreetSegments: ITripCountsInStreetSegments = {
      documents: [],
      next_page: null,
      street_segments: {},
      total_page_num: 1,
      trips_per_street_min: 0,
      trips_per_street_max: 0,
    }
    const calculatedDocuments: Record<string, number> = {}

    for (const [, vehicleData] of selectedVehicleData) {
      const { documents, street_segments, trips_per_street_min, trips_per_street_max } = vehicleData['street_segments']
      calculatedTripCountsInStreetSegments['street_segments'] = Object.assign(
        {},
        calculatedTripCountsInStreetSegments['street_segments'],
        street_segments
      )
      calculatedTripCountsInStreetSegments['trips_per_street_min'] += trips_per_street_min
      calculatedTripCountsInStreetSegments['trips_per_street_max'] += trips_per_street_max
      for (const { key, doc_count } of documents) {
        if (!(key in calculatedDocuments)) {
          calculatedDocuments[key] = doc_count
        } else {
          calculatedDocuments[key] += doc_count
        }
      }
    }

    calculatedTripCountsInStreetSegments['documents'] = Object.entries(calculatedDocuments).map(([key, doc_count]) => ({
      key,
      doc_count,
    }))

    return calculatedTripCountsInStreetSegments
  }, [selectedVehicleData])

  return tripCountsInStreetSegments
}

export const useSelectedVehiclesTypesTripsWithOriginsDestinations = function (
  selectedVehicleData: SelectedVehiclesTypesData
) {
  const origins_destinations = useMemo<
    React.ComponentProps<typeof TripHeatmap>['tripsWithOriginsDestinationsData']
  >(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calculatednumberOfTrips = new Map<string, ITripsWithOriginsDestinations>()

    for (const [, vehicleData] of selectedVehicleData) {
      for (const { trip_count, ...common } of vehicleData['origins_destinations']) {
        // Although using object as Map keys words, it does not compare with deep equality
        // resolving in not merging and summing the parts we want
        const key = JSON.stringify(common)
        const currentTripCount = calculatednumberOfTrips.get(key)?.['trip_count'] ?? 0
        calculatednumberOfTrips.set(key, {
          trip_count: currentTripCount + trip_count,
          ...common,
        })
      }
    }

    return Array.from(calculatednumberOfTrips.values())
  }, [selectedVehicleData])

  return origins_destinations
}

export const useVehicleTypes = function (data: Partial<ICityData> | undefined) {
  if (data === undefined) {
    return []
  }

  return Object.values(VehicleTypesEnum).filter((vehicleType) => vehicleType in data)
}
