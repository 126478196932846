import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DistanceUnit } from './constants'
import formatNumber from './formatNumber'
import { accessorSharedMobilityTableEnum } from './SharedMobilityTable'

interface ISharedMobilityTableFormatCellProps {
  accessor: accessorSharedMobilityTableEnum
  data: { cityName: string; maxVehicles: number; totalDistance: number; totalTrips: number }
  distanceUnit: DistanceUnit
}

function SharedMobilityTableFormatCell(props: ISharedMobilityTableFormatCellProps) {
  const { accessor, data } = props
  const { i18n } = useTranslation()

  switch (accessor) {
    case accessorSharedMobilityTableEnum.CITY:
      return (
        <Typography variant="h4" fontWeight="bold">
          {data.cityName}
        </Typography>
      )
    case accessorSharedMobilityTableEnum.TOTAL_TRIPS:
      return <Typography variant="h4">{formatNumber(i18n.language, data.totalTrips)}</Typography>
    case accessorSharedMobilityTableEnum.TOTAL_DISTANCE:
      return <Typography variant="h4">{formatNumber(i18n.language, data.totalDistance, props.distanceUnit)}</Typography>
    case accessorSharedMobilityTableEnum.MAX_VEHICLES_DEPLOYED:
      return <Typography variant="h4">{formatNumber(i18n.language, data.maxVehicles)}</Typography>
    default:
      return <Typography variant="h4">{accessor}</Typography>
  }
}

export default SharedMobilityTableFormatCell
