import { Accordion, Box, Checkbox, Typography } from '@mui/material'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React, { PropsWithChildren } from 'react'
import Fade from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { BiChevronUp, BiChevronDown } from 'react-icons/bi'

import { useBaseFilterCtx } from './BaseFilter.hooks'

export interface ICollapsibleFilterProps {
  displayCheckbox: boolean
}

export default function CollapsibleFilter({
  children,
  displayCheckbox = true,
}: PropsWithChildren<ICollapsibleFilterProps>) {
  const { t } = useTranslation()
  const { title, subtitle, expanded, toggle, values, items, selectAll, unselectAll } = useBaseFilterCtx()

  const isAllSelected = !!items?.length && values?.length === items.length
  const isNothingSelected = !values?.length

  const getSubtitle = () => {
    if (isAllSelected) {
      return t('filters.checkbox.allSelected')
    } else if (isNothingSelected) {
      return t('filters.checkbox.nothingSelected')
    }

    return t('filters.checkbox.someSelected', { size: values?.length ?? 0, length: items?.length ?? 0 })
  }

  return (
    <Accordion disableGutters expanded={expanded} onChange={toggle} square elevation={0} sx={{ m: 0, width: '100%' }}>
      <AccordionSummary
        expandIcon={expanded ? <BiChevronUp /> : <BiChevronDown />}
        sx={{
          mt: 0,
          mb: 1,
          px: '12px',
          fontSize: '24px',
          display: 'flex',
          alignItems: 'flex-start',
          '&.Mui-expanded': {
            minHeight: 0,
            margin: 0,
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
      >
        {displayCheckbox && (
          <Checkbox
            sx={{
              p: 0,
              minWidth: '18px',
              mr: '7px',
              mt: '3px',
              alignSelf: 'flex-start',
              '& .MuiSvgIcon-root': { fontSize: 26 },
            }}
            color="primary"
            checked={isAllSelected}
            indeterminate={!!values?.length && !!items?.length && values.length < items.length}
            onChange={(_e, checked) => (checked ? selectAll() : unselectAll())}
            onClick={(e) => e.stopPropagation()}
          />
        )}

        <Box display="flex" flexDirection="column" width="100%">
          <Typography sx={(theme) => ({ mb: '3px', color: theme.palette.primary.main })} variant="h2" title={title}>
            {title}
          </Typography>
          <Box display="flex" minHeight="15px">
            {/* <Fade when={!expanded} delay={100}> */}
            <Fade delay={100}>
              <Typography variant="h4">{subtitle ?? getSubtitle()}</Typography>
            </Fade>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          px: '10px',
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
