import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Link } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { CityLogo, ICityLogoProps } from './CityLogo'

interface IHeaderBreadcrumbsProps {
  logo: ICityLogoProps
}

export const HeaderBreadcrumbs = function (props: IHeaderBreadcrumbsProps) {
  const { t } = useTranslation()
  return (
    <Breadcrumbs
      sx={{ px: { xs: 1, sm: 2 } }}
      separator={<NavigateNextIcon fontSize={'medium'} sx={{ color: 'white' }} />}
    >
      <Link
        to={'/'}
        style={{ textDecoration: 'none' }}
        component={RouterLink}
        sx={(theme) => ({ color: 'white', fontSize: theme.typography.h2.fontSize })}
        textTransform={'uppercase'}
      >
        {t('home')}
      </Link>
      <CityLogo path={props.logo.path} name={props.logo.name} />
    </Breadcrumbs>
  )
}
