import { ThemeProvider } from '@mui/material'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import './App.css'
import ScrollToTop from 'Components/ScrollToTop'
import AppRoutes from 'Routes'
import 'i18n/config'
import theme from 'Theme/theme'

/**
 * staleTime option enable to use cached data
 */
const CACHE_TIME = 5 * 3_600 * 1_000 // 5 minutes in milliseconds
const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: CACHE_TIME, cacheTime: CACHE_TIME } } })

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <AppRoutes />
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
