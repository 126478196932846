import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const elements: { key: string; link?: string }[] = [
  { key: 'careers', link: 'https://www.bluesystems.io/careers/' },
  { key: 'terms_of_use', link: 'https://www.bluesystems.io/terms-of-use/' },
  { key: 'privacy_policy', link: 'https://www.bluesystems.io/personal-data-protection-policy/' },
  { key: 'copyright' },
]

function Footer() {
  const { t } = useTranslation()

  return (
    <Stack
      direction="column"
      alignItems="center"
      width={1}
      py={8}
      sx={(theme) => ({
        bgcolor: theme.palette.grey['100'],
      })}
    >
      <Stack direction={{ sm: 'column', md: 'row' }} alignItems="flex-start" gap={{ xs: 2, md: 8 }} px={2}>
        {elements.map((elem) => (
          <a
            key={elem.key}
            href={elem.link}
            style={{
              textDecoration: 'none',
            }}
          >
            <Typography variant="h5" sx={(theme) => ({ color: theme.palette.primary.main })}>
              {t(`footer.${elem.key}`)}
            </Typography>
          </a>
        ))}
      </Stack>
    </Stack>
  )
}

export default Footer
