export const BLUESYSTEMS_URL = 'https://www.bluesystems.io/'

export enum DistanceUnit {
  km = 'km',
  miles = 'miles',
  yards = 'yards',
}

export enum LocalStorageKey {
  DISTANCE_UNIT = 'distanceUnit',
}
