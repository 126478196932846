import MapboxGl from 'mapbox-gl'
import React, { useContext } from 'react'

// import GeoJSONLayer from './GeoJsonLayer'

export type MapEvent = (
  map: MapboxGl.Map,

  evt: React.SyntheticEvent<unknown>
) => void

export interface IViewport {
  center: {
    lat: number
    lng: number
  }
  zoom: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGeoJsonData extends GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> {}

export interface IMapContent {
  geometry: IGeoJsonData
  id: string
  options: string
}

export const MapContext = React.createContext(undefined) as React.Context<MapboxGl.Map | undefined>

export const useMap = () => {
  return useContext(MapContext)
}

// export const _testState: { lastRenderGeoJsonLayersReturn: any } = { lastRenderGeoJsonLayersReturn: null }

// export function useMapContent(
//   init: IMapContent[]
// ): [
//   IMapContent[],
//   (id: string, options: any, geometry: IGeoJsonData) => void,
//   (id: string) => void,
//   (id: string) => IMapContent | undefined,
//   () => void,
//   () => JSX.Element[]
// ] {
//   const [mapContent, setMapContent] = useState<IMapContent[]>(init)

//   const setContent = (id: string, options: any, geometry: IGeoJsonData) => {
//     setMapContent((content) =>
//       content
//         .filter((c) => c.id !== id)
//         .concat([
//           {
//             id,
//             options,
//             geometry,
//           },
//         ])
//     )
//   }

//   const removeContent = (id: string) => {
//     setMapContent((c: IMapContent[]) => c.filter((content: IMapContent) => content.id !== id))
//   }

//   const clearContent = () => {
//     setMapContent([])
//   }

//   const getContent = (id: string) => {
//     return mapContent.find((c: IMapContent) => c.id === id)
//   }

//   function renderGeoJsonLayers() {
//     const layers = mapContent.map((c) => <GeoJSONLayer key={c.id} geoData={c.geometry} layerOptions={c.options} />)
//     _testState.lastRenderGeoJsonLayersReturn = layers
//     return layers
//   }

//   return [mapContent, setContent, removeContent, getContent, clearContent, renderGeoJsonLayers]
// }

// export { default as GeoJSONLayer } from './GeoJsonLayer'
// export { default as DrawLayer } from './DrawLayer'
export { default as MapBoxMap } from './MapBoxMap'
// export { default as MapPopup } from './MapPopup'
// export { default as MapActionBar } from './MapActionBar'
// export { default as MapSpinner } from './MapSpinner'
// export { default as MapProgressBar } from './MapProgressBar'
