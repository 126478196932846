import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import CityMetric from 'Components/CityMetric'
import { DistanceUnit } from 'Components/constants'
import { IVehicleData } from 'Components/FetchData'
import formatDate from 'Components/formatDate'
import formatNumber from 'Components/formatNumber'
import { Theme } from 'Theme/theme'

interface ICityMetricsProps {
  distanceUnit: DistanceUnit
  metrics: IVehicleData['metrics']
}

export const CityMetrics = function (props: ICityMetricsProps) {
  const { t, i18n } = useTranslation()

  return (
    <Stack direction={'column'} spacing={{ xs: 4, md: 6 }} paddingX={{ xs: 2 }}>
      <Typography variant="h2" sx={(theme: Theme) => ({ color: theme.palette.primary.main })}>
        <Trans
          i18nKey="city_page.metrics.title"
          values={{
            from: formatDate(i18n.language, props.metrics.from),
            to: formatDate(i18n.language, props.metrics.to),
          }}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
        />
      </Typography>
      <CityMetric
        title={t('city_page.metrics.average_number_of_trips_per_day')}
        value={formatNumber(i18n.language, props.metrics.average_number_of_trips_per_day)}
      />
      <CityMetric
        title={t('city_page.metrics.average_trips_distance')}
        value={formatNumber(i18n.language, props.metrics.average_trips_distance, props.distanceUnit)}
      />
      <CityMetric
        title={t('city_page.metrics.average_trips_duration')}
        value={formatNumber(i18n.language, props.metrics.average_trips_duration, 'min')}
      />
      <CityMetric
        title={t('city_page.metrics.total_trips')}
        value={formatNumber(i18n.language, props.metrics.total_trips)}
      />
      <CityMetric
        title={t('city_page.metrics.total_distance')}
        value={formatNumber(i18n.language, props.metrics.total_distance, props.distanceUnit)}
      />
      <CityMetric
        title={t('city_page.metrics.max_vehicles_deployed')}
        value={formatNumber(i18n.language, props.metrics.max_vehicles_deployed)}
      />
    </Stack>
  )
}
