import { Card, Stack, Typography } from '@mui/material'
import React from 'react'

interface IDataCardProps {
  primaryText: string
  secondaryText?: string | null
}

function DataCard(props: IDataCardProps) {
  const { primaryText, secondaryText } = props

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        p: 4,
        bgcolor: theme.palette.primary.light,
        opacity: 0.8,
        width: 1,
      })}
    >
      <Stack direction="column" gap={1} textAlign="center">
        <Typography variant="h1" sx={(theme) => ({ color: theme.palette.background.default })}>
          {primaryText}
        </Typography>
        {secondaryText && (
          <Typography variant="h5" sx={(theme) => ({ color: theme.palette.background.default })}>
            {secondaryText}
          </Typography>
        )}
      </Stack>
    </Card>
  )
}

export default DataCard
