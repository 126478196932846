import { upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'

import { VEHICLES_CATEGORY_ICON_MAP } from 'commons/constants'
import { IFacet, ISection, IEntry } from 'commons/types'

export function useEnumEntries(
  filterEnum: string[],
  translationEnum: { [index: string]: string },
  counter?: IFacet,
  plural = true
) {
  const { t } = useTranslation()
  return filterEnum.map((enumItem) => {
    const name = translationEnum[enumItem]
    const translatedName = plural ? upperFirst(t(name, { count: 2 }) as string) : upperFirst(t(name) as string)

    return {
      id: enumItem,
      label: translatedName,
      value: counter && counter[enumItem],
    }
  }) as IEntry[]
}

export function useVehicleTypeSection(args: { disabled?: boolean; entries: IEntry[]; filterType?: string }): ISection {
  const { t } = useTranslation()
  return {
    filterType: 'category',
    formatValue: true,
    showSelectAll: true,
    title: upperFirst(t('filtersPanel.vehicleType') as string),
    ...args,
  }
}

export function iconMap(vehicleType: string) {
  return VEHICLES_CATEGORY_ICON_MAP[vehicleType]
}
