import { SvgIcon } from '@mui/material'
import React from 'react'

const CargoBikeIcon = (props: any) => {
  return (
    <SvgIcon x="0px" y="0px" width="508.204pt" height="277.561pt" viewBox="0 0 508.204 277.561" {...props}>
      <circle strokeWidth="14.173" stroke="#000" cy="200.55" cx="76.181" strokeMiterlimit="10" r="69.094" fill="none" />
      <circle cy="200.55" cx="76.181" r="7.81" />
      <line
        strokeWidth="11.339"
        y2="200.55"
        y1="54.31"
        stroke="#000"
        strokeMiterlimit="10"
        x2="190.99"
        x1="117.99"
        fill="none"
      />
      <line
        strokeWidth="11.339"
        y2="200.55"
        y1="200.55"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x2="393.84"
        x1="81.344"
        fill="none"
      />
      <line
        strokeWidth="11.339"
        y2="200.55"
        y1="131.46"
        stroke="#000"
        strokeMiterlimit="10"
        x2="77.606"
        x1="152.02"
        fill="none"
      />
      <path stroke="#000" strokeWidth="11.339" strokeMiterlimit="10" d="m185.28 209.81" fill="none" />
      <path stroke="#000" strokeWidth="11.339" strokeMiterlimit="10" d="m210.28 171.86" fill="none" />
      <g stroke="#000" strokeWidth="11.339" strokeMiterlimit="10" fill="none">
        <line x2="215.88" y1="238.2" x1="166.27" y2="162.9" />
        <line x2="227.66" y1="162.9" x1="203.77" y2="162.9" strokeLinecap="round" />
        <line x2="178.22" y1="238.2" x1="154.32" y2="238.2" strokeLinecap="round" />
      </g>
      <line
        strokeWidth="17.008"
        y2="54.31"
        y1="54.31"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x2="141.05"
        x1="100.78"
        fill="none"
      />
      <path d="m502.54 195.81l-240-0.919c4.875 0 4.331-1.619 4.331-4.75v-140c0-1.565 0.647-3.062 1.788-4.133 1.142-1.071 2.665-1.625 4.237-1.525l230 14.5c2.987 0.188 5.313 2.666 5.313 5.658v125.5c-0.01 3.13-2.54 5.67-5.67 5.67z" />
      <circle strokeWidth="14.173" stroke="#000" cy="207.97" cx="394.62" strokeMiterlimit="10" r="62.505" fill="#fff" />
      <circle cy="207.97" cx="394.62" r="11.31" />
      <polyline
        strokeLinejoin="round"
        strokeLinecap="round"
        stroke="#000"
        points="271.28 50.31 260.42 27.81 228.28 6.31"
        strokeMiterlimit="10"
        strokeWidth="8.5039"
        fill="none"
      />
      <circle cy="6.31" cx="228.28" r="6.31" />
    </SvgIcon>
  )
}

export default CargoBikeIcon
