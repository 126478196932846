import _ from 'lodash'
import React, { PropsWithChildren, createContext, useContext } from 'react'

import useDeepCompareEffect from 'commons/hooks/useDeepCompareEffect'

import { IBaseFilterProps } from '../BaseFilter/BaseFilter'
import { useBaseFilterCtx } from '../BaseFilter/BaseFilter.hooks'

export interface ICheckboxFilterWrapperProps extends IBaseFilterProps {
  onFilterChange?: (filterId: string, values: string[]) => void
}

interface ICheckboxFilterCtxType {
  checkItem: (id: string, status: boolean) => void
}

const CheckBoxFilterCtx = createContext<ICheckboxFilterCtxType | undefined>(undefined)

export function useCheckboxFilterCtx() {
  const ctx = useContext(CheckBoxFilterCtx)

  if (!ctx) {
    throw new Error('useCheckboxFilterCtx have to be inside CheckboxFilterCtx wrapper')
  }

  return ctx
}

export default function CheckboxFilterWrapper({
  children,
  filterId,
  ...props
}: PropsWithChildren<ICheckboxFilterWrapperProps>) {
  const { values, setValues } = useBaseFilterCtx()

  const handleCheckItem = (id: string, checked: boolean) => {
    if (checked) {
      setValues((v) => _.union(v, [id]))
    } else {
      setValues((v) => v.filter((vId) => vId !== id))
    }
  }

  useDeepCompareEffect(() => {
    props?.onFilterChange?.(filterId, values as string[])
  }, [values])

  function getContext() {
    return {
      checkItem: (id: string, status: boolean) => handleCheckItem(id, status),
    }
  }

  return <CheckBoxFilterCtx.Provider value={getContext()}>{children}</CheckBoxFilterCtx.Provider>
}
