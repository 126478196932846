import React from 'react'
import { Routes, Route } from 'react-router-dom'

import CityPage from 'Pages/City/CityPage'
import DataPrivacy from 'Pages/DataPrivacy'
import HomePage from 'Pages/HomePage'
import UnknownPage from 'Pages/UnknownPage'

export const RoutesPaths = {
  HOME: '/',
  CITY_PAGE: (cityName = ':cityName') => `/city/${cityName}`,
  DATA_PRIVACY: '/privacy',
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="*" element={<UnknownPage />} />
      <Route path={RoutesPaths.HOME} element={<HomePage />} />
      <Route path={RoutesPaths.CITY_PAGE()} element={<CityPage />} />
      <Route path={RoutesPaths.DATA_PRIVACY} element={<DataPrivacy />} />
    </Routes>
  )
}

export default AppRoutes
