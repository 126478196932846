import { useEffect, useMemo, useState } from 'react'

import { LocalStorageKey } from 'Components/constants'

export const useLocalStorage = function <Value>(
  key: LocalStorageKey,
  defaultValue: Value
): [Value, React.Dispatch<React.SetStateAction<Value>>] {
  const defaultValueJSON = useMemo(() => JSON.stringify(defaultValue), [defaultValue])
  const [value, setState] = useState<Value>(JSON.parse(localStorage.getItem(key) ?? defaultValueJSON))

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setState]
}
