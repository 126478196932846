import { SvgIcon } from '@mui/material'
import React from 'react'

const DeliveryBotIcon = (props: any) => {
  return (
    <SvgIcon x="0px" y="0px" width="462.271pt" height="447.16101pt" viewBox="0 0 462.271 447.16101" {...props}>
      <g id="g12" transform="translate(-118.9,-56.422)">
        <path
          d="m 493.61,178.92 h -8.75 v -70 h -87.5 v 35 h 52.5 v 35 H 206.4 c -23.195,0.0312 -45.434,9.2578 -61.836,25.66 -16.402,16.406 -25.633,38.641 -25.664,61.84 v 175 h 35 v -175 c 0,-13.926 5.5312,-27.281 15.379,-37.125 9.8438,-9.8438 23.199,-15.375 37.121,-15.375 h 126.11 v 87.5 h 35 v -87.5 h 126.11 c 11.77,0.0273 23.191,4.0117 32.43,11.309 9.2344,7.3008 15.75,17.492 18.496,28.941 h -70.125 v 35 h 71.75 v 152.09 h 35 v -175 c -0.0742,-23.176 -9.3281,-45.383 -25.738,-61.754 -16.406,-16.367 -38.633,-25.57 -61.812,-25.586 z"
          id="path2"
        />
        <path
          d="m 232.75,379.09 c -16.508,0.004 -32.34,6.5664 -44.008,18.242 -11.672,11.676 -18.227,27.508 -18.223,44.016 0.004,16.508 6.5625,32.34 18.238,44.012 11.676,11.672 27.508,18.227 44.016,18.223 16.508,0 32.34,-6.5586 44.012,-18.23 11.672,-11.676 18.23,-27.508 18.23,-44.016 -0.0195,-16.508 -6.5859,-32.332 -18.258,-44 -11.676,-11.672 -27.5,-18.234 -44.008,-18.246 z m 0,89.496 v -0.004 c -7.2266,-0.004 -14.152,-2.8789 -19.258,-7.9883 -5.1094,-5.1133 -7.9766,-12.043 -7.9727,-19.27 0.004,-7.2266 2.875,-14.152 7.9883,-19.262 5.1094,-5.1055 12.039,-7.9766 19.266,-7.9727 7.2266,0 14.156,2.8711 19.262,7.9805 5.1094,5.1094 7.9805,12.039 7.9805,19.266 -0.0117,7.2266 -2.8867,14.156 -7.9961,19.262 -5.1133,5.1094 -12.043,7.9805 -19.27,7.9844 z"
          id="path4"
        />
        <path
          d="m 467.25,379.09 c -16.512,-0.004 -32.344,6.5508 -44.023,18.219 -11.676,11.672 -18.238,27.508 -18.242,44.016 -0.004,16.512 6.5547,32.344 18.227,44.023 11.672,11.676 27.504,18.234 44.016,18.234 16.512,0.004 32.344,-6.5547 44.02,-18.227 11.676,-11.676 18.234,-27.508 18.234,-44.02 -0.0195,-16.5 -6.582,-32.32 -18.246,-43.988 -11.668,-11.668 -27.484,-18.234 -43.984,-18.258 z m 0,89.496 v -0.004 c -7.2266,0.008 -14.16,-2.8594 -19.273,-7.9688 -5.1133,-5.1094 -7.9883,-12.039 -7.9922,-19.266 -0.004,-7.227 2.8672,-14.16 7.9766,-19.273 5.1094,-5.1094 12.039,-7.9844 19.266,-7.9844 7.2266,-0.004 14.16,2.8672 19.27,7.9766 5.1133,5.1094 7.9844,12.043 7.9844,19.27 -0.008,7.2227 -2.8828,14.145 -7.9844,19.25 -5.1055,5.1094 -12.027,7.9844 -19.246,7.9961 z"
          id="path6"
        />
        <path d="m 312.59,423.83 h 74.828 v 35 H 312.59 Z" id="path8" />
        <path d="m 397.36,56.422 h 87.484 v 35 H 397.36 Z" id="path10" />
      </g>
    </SvgIcon>
  )
}

export default DeliveryBotIcon
