import { Link, Typography } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface ICityCardProps {
  cityName: string
  disabled?: boolean
  imagePath: string
  url: string
  width: number
}

function CityCard(props: ICityCardProps) {
  const { imagePath, url, disabled } = props

  return (
    <Link
      component={RouterLink}
      to={`city/${url}`}
      flexBasis={`${props.width + 1}rem`}
      flexGrow={0}
      flexShrink={0}
      underline={'none'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={1}
      sx={{
        pointerEvents: disabled ? 'none' : 'initial',
        cursor: disabled ? 'default' : 'pointer',
        backgroundImage: `url(/${imagePath})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        filter: disabled ? 'grayscale(100%)' : 'initial',
        '&:hover': disabled ? {} : { transform: 'scale(1.1)' },
        transition: 'transform 0.2s',
      }}
    >
      <Typography variant={'h2'} sx={(theme) => ({ color: theme.palette.background.default })} paddingY={4}>
        {props.cityName}
      </Typography>
    </Link>
  )
}

export default CityCard
