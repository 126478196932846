import { Stack, Typography } from '@mui/material'
import React from 'react'

export interface ICityLogoProps {
  name: string
  path: string
}

export const CityLogo = function (props: ICityLogoProps) {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={2}>
      <img
        src={props.path}
        alt={`${props.name} logo`}
        style={{
          height: '60px',
        }}
      />
      <Typography textAlign={'center'} variant={'h1'} color={'white'}>
        {props.name}
      </Typography>
    </Stack>
  )
}
