import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

export interface IQuantileColorScale {
  colors: string[]
  formattedQuantiles: string[]
  values: number[]
}

export function createQuantileBasedColorScale(
  array: number[], // ex : [1, 6, 9, 4, 6]
  colors: string[], // ex : ['#84CA50', '#F07D02', '#E60000', '#9E1313']
  quantiles: number[] // ex: [50, 75, 95]
): IQuantileColorScale {
  if (colors.length === 0) {
    throw TypeError('There should be at least one color')
  }
  // Because each color is between two ticks, we have to do the following check :
  if (colors.length !== quantiles.length + 1) {
    throw TypeError(`colors length ${colors.length} is not equal to quantile length + 1 = ${quantiles.length + 1}`)
  }

  // Compute the given quantiles
  const sortedArray = array.sort((a: number, b: number) => a - b)
  const numberOfElements = sortedArray.length
  let formattedQuantiles: string[] = []
  let values: number[] = []
  let previousValue = sortedArray[0]
  const selectedColors: string[] = []
  let colorIndex = 0
  for (const quantile of quantiles) {
    const index = Math.floor(((numberOfElements - 1) * quantile) / 100)
    // Make sure the value changed and is different from the last element (that will be added automatically as the max)
    if (sortedArray[index] !== previousValue && sortedArray[index] !== sortedArray[numberOfElements - 1]) {
      previousValue = sortedArray[index]
      values.push(sortedArray[index])
      formattedQuantiles.push(`${quantile}%`)
      selectedColors.push(colors[colorIndex])
      colorIndex += 1
    }
  }

  // Add minimum and maximum
  formattedQuantiles = ['min', ...formattedQuantiles, 'max']
  values = [
    sortedArray[0],
    ...values,
    // In the case where numberOfDifferentElements==1, this ensures us to keep a strict ascend order in the scale
    sortedArray[numberOfElements - 1] === sortedArray[0] ? sortedArray[0] + 1 : sortedArray[numberOfElements - 1],
  ]
  selectedColors.push(colors[colorIndex])

  return {
    colors: selectedColors,
    formattedQuantiles,
    values,
  }
}

interface IProps {
  colorScale: IQuantileColorScale | null
  noDataText: string
  quantilesText: string
  valuesText: string
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  text: {
    fontSize: 13,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  range: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    width: '100%',
  },
  tick: {
    position: 'absolute',
  },
  tickText: {
    marginLeft: '-50%',
    fontSize: 12,
  },
  color: {
    flexBasis: '100%',
    height: '100%',
  },
})

const QuantileBasedLegend = (props: IProps) => {
  const classes = useStyles()

  if (!props.colorScale) {
    return <div className={classNames(classes.root, classes.text)}>{props.noDataText}</div>
  }

  const { colors, formattedQuantiles, values } = props.colorScale

  function getTickLeftPosition(tickNumber: number, numberOfTicks: number) {
    if (tickNumber === 0) {
      return '0%'
    }
    if (tickNumber === numberOfTicks - 1) {
      return '100%'
    }
    return `${(tickNumber / (numberOfTicks - 1)) * 100}%`
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>{props.quantilesText}</Typography>
      <div className={classes.range}>
        {formattedQuantiles.map(
          (quantile: string, index: number): ReactNode => (
            <div
              className={classes.tick}
              key={quantile}
              style={{ left: getTickLeftPosition(index, formattedQuantiles.length) }}
            >
              <Typography className={classes.tickText}>{quantile}</Typography>
            </div>
          )
        )}
      </div>
      <div className={classes.range}>
        {colors.map(
          (color: string): ReactNode => (
            <div key={color} className={classes.color} style={{ backgroundColor: color }} />
          )
        )}
      </div>
      <div className={classes.range}>
        {values.map(
          (value: number, index: number): ReactNode => (
            <div className={classes.tick} key={index} style={{ left: getTickLeftPosition(index, values.length) }}>
              <Typography className={classes.tickText}>{value}</Typography>
            </div>
          )
        )}
      </div>
      <Typography className={classes.text}>{props.valuesText}</Typography>
    </div>
  )
}

export default QuantileBasedLegend
