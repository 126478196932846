import { CircularProgress, Stack } from '@mui/material'
import React, { useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueries } from 'react-query'
import { Navigate } from 'react-router-dom'

import { VehicleTypesEnum } from 'commons/Enums'
import { DistanceUnit, LocalStorageKey } from 'Components/constants'
import { fetchCityMetaData, fetchFullData, ICityData } from 'Components/FetchData'
import Footer from 'Components/Footer'
import TripHeatmap from 'Components/MapBox/TripHeatmap'
import CheckboxListFilter from 'Components/SidePanel/Filters/CheckboxListFilter/CheckboxListFilter'
import TemporalHistogram, { GranularityEnum } from 'Components/TemporalHistogram'
import { useCityName } from 'hooks/useCityName'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useEnumEntries, useVehicleTypeSection } from 'utils'

import { CityDescription } from './CityDescription'
import { CityMetrics } from './CityMetrics'
import {
  useSelectedVehicleData,
  useSelectedVehiclesTypesMetrics,
  useSelectedVehiclesTypesNumberOfTrips,
  useSelectedVehiclesTypesNumberOfVehiclesDeployed,
  useSelectedVehiclesTypesRatioUsage,
  useSelectedVehiclesTypesTripsCountInStreetSegments,
  useSelectedVehiclesTypesTripsWithOriginsDestinations,
  useVehicleTypes,
} from './CityPageHooks'
import { Header } from './Header'
import { ProvidersLogo } from './ProvidersLogo'

function CityPage() {
  const { t } = useTranslation()
  const [, startTransition] = useTransition()
  const cityName = useCityName()

  const [distanceUnit, setDistanceUnit] = useLocalStorage<DistanceUnit>(LocalStorageKey.DISTANCE_UNIT, DistanceUnit.km)
  const [metaDataResults, graphDataResults] = useQueries([
    { queryKey: ['fetchMetaData', cityName], queryFn: () => fetchCityMetaData(cityName) },
    {
      queryKey: ['fetchCityData', distanceUnit, cityName],
      queryFn: () => fetchFullData(`/datas/${distanceUnit}/${cityName}.json`),
      keepPreviousData: true,
    },
  ])
  const isLoading = metaDataResults.isLoading || graphDataResults.isLoading
  const error = metaDataResults.error || graphDataResults.error

  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<Set<keyof ICityData>>(new Set())
  const selectedVehicleData = useSelectedVehicleData(selectedVehicleTypes, graphDataResults.data, isLoading, error)

  const metrics = useSelectedVehiclesTypesMetrics(selectedVehicleData)
  const ratioUsage = useSelectedVehiclesTypesRatioUsage(selectedVehicleData)
  const numberOfTrips = useSelectedVehiclesTypesNumberOfTrips(selectedVehicleData)
  const numberOfVehiclesDeployed = useSelectedVehiclesTypesNumberOfVehiclesDeployed(selectedVehicleData)
  const tripCountsInStreetSegments = useSelectedVehiclesTypesTripsCountInStreetSegments(selectedVehicleData)
  const tripsWithOriginsDestinations = useSelectedVehiclesTypesTripsWithOriginsDestinations(selectedVehicleData)

  const vehicleTypeWithDataList = useVehicleTypes(graphDataResults.data)
  const vehicleTypeSection = useVehicleTypeSection({
    entries: useEnumEntries(vehicleTypeWithDataList, VehicleTypesEnum),
    filterType: 'vehicleTypes',
  })

  if (metaDataResults.isLoading) {
    return <CircularProgress sx={{ position: 'absolute', inset: 0, margin: 'auto' }} />
  }

  if (error) {
    return <Navigate to="/" />
  }

  const metaData = metaDataResults.data!

  return (
    <Stack direction="column" alignItems="center" gap={4}>
      <Header
        distanceUnit={distanceUnit}
        setDistanceUnit={(distanceUnit: DistanceUnit) => startTransition(() => setDistanceUnit(distanceUnit))}
        metaData={metaData}
      />
      <Stack direction="column" alignItems="center" gap={8}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={'flex-start'}
          justifyContent={{ md: 'space-evenly' }}
          spacing={{ xs: 5 }}
        >
          <Stack direction={'column'} width={{ xs: 6 / 7, md: 1 / 7 }} spacing={6} alignItems={'center'}>
            <ProvidersLogo providersLogo={metaData.providersLogo} />
            <CheckboxListFilter
              filterId="Vehicle_types"
              title={t('city_page.device_types') as string}
              items={vehicleTypeSection.entries.map((vt) => ({ id: vt.id, label: t(`device.${vt.id}`) }))}
              values={vehicleTypeWithDataList.map((vt) => vt)}
              onFilterChange={(filterId, values) => {
                setSelectedVehicleTypes(new Set(values.map((vt) => vt as keyof typeof VehicleTypesEnum)))
              }}
            />
            <CityMetrics metrics={metrics} distanceUnit={distanceUnit} />
          </Stack>
          <Stack direction={'column'} width={{ md: 5 / 7 }} alignItems={'center'} spacing={6}>
            {metaData.description.length ? <CityDescription description={metaData.description} /> : null}
            <TripHeatmap
              viewport={metaData.viewport}
              tripCountsInStreetSegmentsData={tripCountsInStreetSegments}
              tripsWithOriginsDestinationsData={tripsWithOriginsDestinations}
              isLoading={isLoading}
            />
            {metaData.kpis.number_of_trips && (
              <TemporalHistogram
                granularity={GranularityEnum.day}
                title={t('city_page.number_of_trips')}
                total
                rawData={numberOfTrips}
              />
            )}
            {metaData.kpis.ratio_of_time_used && (
              <TemporalHistogram
                granularity={GranularityEnum.day}
                title={t('city_page.ratio_of_time_in_use')}
                rawData={ratioUsage}
                percentage
              />
            )}
            {metaData.kpis.number_of_vehicles_deployed && (
              <TemporalHistogram
                granularity={GranularityEnum.day}
                title={t('city_page.number_of_vehicles_deployed')}
                rawData={numberOfVehiclesDeployed}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <Footer />
    </Stack>
  )
}

export default CityPage
