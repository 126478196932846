import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEn from 'i18n/en/translation.json'
import translationFr from 'i18n/fr/translation.json'

const resources = {
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
}

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  resources,
})
