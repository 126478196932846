import { Checkbox, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { BiQuestionMark } from 'react-icons/bi'

import { iconMap } from 'utils'

import { IBaseFilterItem } from '../BaseFilter/BaseFilter'
import { useBaseFilterCtx } from '../BaseFilter/BaseFilter.hooks'

import { useCheckboxFilterCtx } from './CheckboxListFilter.hooks'

export default function CheckboxListFilterItem({ id, label, endAdornment }: IBaseFilterItem) {
  const { values } = useBaseFilterCtx()
  const { checkItem } = useCheckboxFilterCtx()

  const checked = useMemo(() => {
    return values?.includes(id)
  }, [id, values])

  const CurrentIcon = iconMap(id) ?? BiQuestionMark

  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
      <Checkbox
        sx={{ m: 0, mr: '7px', p: 0, '& .MuiSvgIcon-root': { fontSize: 26 } }}
        checked={checked}
        onChange={(_e, checked) => checkItem(id, checked)}
      />
      <Typography variant="h4" sx={{ flex: 1 }}>
        {label}
      </Typography>
      {endAdornment}
      <CurrentIcon style={{ fontSize: 20, color: 'black', opacity: 0.6 }} />
    </Stack>
  )
}
