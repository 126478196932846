import { Stack } from '@mui/material'
import React from 'react'

import { BLUESYSTEMS_URL } from 'Components/constants'

export const BlueSystemsLogo = function () {
  return (
    <Stack mb={8} sx={{ px: { xs: 4, sm: 8 } }}>
      <a href={BLUESYSTEMS_URL}>
        <img src={'/logo_bluesystems.svg'} alt={'bluesystems logo'} style={{ width: '13rem' }} />
      </a>
    </Stack>
  )
}
