import { FormControlLabel, Radio } from '@mui/material'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Heatmap } from './constants'

const styles = createStyles({
  radioButtons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  formControlRoot: {
    margin: 0,
  },
  formControlLabel: {
    fontSize: 13,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

interface ILegendProps extends WithStyles<typeof styles> {
  onToggleCheck: (checked: Heatmap) => void
  selectedHeatmap: Heatmap
}

const LegendRadioButtons = (props: ILegendProps) => {
  const { classes, selectedHeatmap, onToggleCheck } = props

  const { t } = useTranslation()

  return (
    <div className={classes.radioButtons}>
      <FormControlLabel
        key={Heatmap.Origin.toString()}
        label={t('city_page.heatmap.origins')}
        classes={{
          root: classes.formControlRoot,
          label: classes.formControlLabel,
        }}
        control={
          <Radio
            color="primary"
            onClick={() => onToggleCheck(Heatmap.Origin)}
            checked={selectedHeatmap === Heatmap.Origin}
          />
        }
      />
      <FormControlLabel
        key={Heatmap.Destination}
        label={t('city_page.heatmap.destinations')}
        classes={{
          root: classes.formControlRoot,
          label: classes.formControlLabel,
        }}
        control={
          <Radio
            color="primary"
            onClick={() => onToggleCheck(Heatmap.Destination)}
            checked={selectedHeatmap === Heatmap.Destination}
          />
        }
      />
      <FormControlLabel
        key={Heatmap.Trips}
        label={t('city_page.heatmap.trips')}
        classes={{
          root: classes.formControlRoot,
          label: classes.formControlLabel,
        }}
        control={
          <Radio
            color="primary"
            onClick={() => onToggleCheck(Heatmap.Trips)}
            checked={selectedHeatmap === Heatmap.Trips}
          />
        }
      />
    </div>
  )
}

export default withStyles(styles)(LegendRadioButtons)
