import { Stack, Typography } from '@mui/material'
import React from 'react'

interface ICityMetricProps {
  title: string
  value?: number | string
}

function CityMetric(props: ICityMetricProps) {
  const { title, value } = props

  return (
    <Stack
      direction="column"
      gap={1}
      sx={(theme) => ({
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        borderBottomColor: theme.palette.primary.main,
      })}
    >
      <Typography variant="h5" sx={(theme) => ({ color: theme.palette.primary.main })}>
        {title}
      </Typography>
      <Typography variant="h4">{value ?? ''}</Typography>
    </Stack>
  )
}

export default CityMetric
