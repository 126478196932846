import { Box } from '@mui/material'
import React, { PropsWithChildren } from 'react'

import BaseFilterWrapper from './BaseFilter.hooks'

export interface IBaseFilterItem {
  endAdornment?: React.ReactNode
  id: string
  label: string
}

export interface IBaseFilterProps {
  filterId: string
  initialValues?: unknown[]
  items?: IBaseFilterItem[]
  subtitle?: string
  title: string
  values?: unknown[]
}

export default function BaseFilter({ children, ...props }: PropsWithChildren<IBaseFilterProps>) {
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <BaseFilterWrapper values={props.values} initialValues={props.initialValues} {...props}>
        {children}
      </BaseFilterWrapper>
    </Box>
  )
}
