const typoTheme = {
  typography: {
    h1: {
      fontWeight: 'bold',
      fontSize: '32px',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '28px',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '20px',
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '16px',
    },
    h5: {
      fontWeight: 'lighter',
      fontSize: '14px',
    },
  },
}

export default typoTheme
