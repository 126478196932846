function formatNumber(lng: string, nb?: number, suffix?: string) {
  if (nb === undefined) {
    return ''
  }
  const formatted = Intl.NumberFormat(lng).format(nb)
  if (suffix) {
    return `${formatted} ${suffix}`
  }
  return formatted
}

export default formatNumber
