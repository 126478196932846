import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProvidersLogoProps {
  providersLogo: Record<string, string>
}

export const ProvidersLogo = function (props: IProvidersLogoProps) {
  const { t } = useTranslation()

  return (
    <Stack direction={'column'}>
      <Typography variant={'h2'} sx={{ color: 'primary.main', mb: 1 }} textTransform={'capitalize'}>
        {t('city_page.providers')}
      </Typography>
      <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
        {Object.entries(props.providersLogo).map(([providerName, logoPath]) => (
          <img
            alt={providerName}
            key={providerName}
            src={`/${logoPath}`}
            style={{ width: '3rem', objectFit: 'contain' }}
          />
        ))}
      </Stack>
    </Stack>
  )
}
