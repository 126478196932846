import { createTheme } from '@mui/material'

import paletteTheme from 'Theme/paletteTheme'
import typoTheme from 'Theme/typoTheme'

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface CustomPalette {
    blue1: SimplePaletteColorOptions
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Palette extends CustomPalette {
    blues: Palette['primary']
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface PaletteOptions extends CustomPalette {
    blues: PaletteOptions['primary']
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface PaletteColor {
    blue1?: string
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface SimplePaletteColorOptions {
    blue1?: string
  }
}

let theme = createTheme(paletteTheme)
theme = createTheme(theme, typoTheme)

export default theme
export type Theme = typeof theme
