import { DistanceUnit } from './constants'
import { IViewport } from './MapBox'
import { ITripCountsInStreetSegments, ITripsWithOriginsDestinations } from './MapBox/TripHeatmap'

export interface IVehicleData {
  metrics: {
    average_number_of_trips_per_day: number
    average_trips_distance: number
    average_trips_duration: number
    from: number
    max_vehicles_deployed: number
    to: number
    total_distance: number
    total_trips: number
  }
  numberOfTrips: { doc_count: number; key: string; result: { buckets: never[] } }[]
  numberOfVehiclesDeployed: {
    key: string
    stackedBy: {
      buckets: {
        doc_count: number
        key: string
      }[]
    }
  }[]
  origins_destinations: ITripsWithOriginsDestinations[]
  ratioUsage: { doc_count: number; key: string }[]
  street_segments: ITripCountsInStreetSegments
}

export interface ICityData {
  bicycle: IVehicleData
  car: IVehicleData
  cargo_bicycle: IVehicleData
  delivery_bot: IVehicleData
  moped: IVehicleData
  other: IVehicleData
  scooter: IVehicleData
}

export async function fetchFullData(url: string): Promise<ICityData> {
  const result = await fetch(url)
  if (!result.ok) {
    throw new Error(`Failed to fetch city data on ${url}`)
  }
  return await result.json()
}

export interface ITableData {
  from: number
  table: {
    max_vehicles_deployed: number
    name: string
    total_distance: number
    total_trips: number
  }[]
  to: number
}

export async function fetchTableData(distance_unit: DistanceUnit): Promise<ITableData> {
  const result = await fetch(`/datas/${distance_unit}/table.json`)
  if (!result.ok) {
    throw new Error(`Failed to fetch table data for ${distance_unit}`)
  }
  return await result.json()
}

export interface IMetaData {
  description: string
  kpis: {
    number_of_trips: boolean
    number_of_vehicles_deployed: boolean
    ratio_of_time_used: boolean
  }
  logoPath: string
  name: string
  providersLogo: Record<string, string>
  viewport: IViewport
}

export async function fetchCityMetaData(cityName: string): Promise<IMetaData> {
  const result = await fetch(`/datas/${cityName}.json`)
  if (!result.ok) {
    throw new Error(`Failed to fetch meta data for ${cityName}`)
  }
  return await result.json()
}

interface ICard {
  cardPath: string
  enabled: boolean
  name: string
  slug: string
}

export const fetchCards = async function (): Promise<ICard[]> {
  const result = await fetch('/datas/cards.json')
  if (!result.ok) {
    throw new Error(`Failed to fetch cards`)
  }
  return await result.json()
}
