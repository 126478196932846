import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BLUESYSTEMS_URL } from 'Components/constants'
// import PolicyIcon from '@material-ui/icons/Policy';

function DataPrivacy() {
  const { t } = useTranslation()

  return (
    <Stack direction="column" alignItems="center" gap={4}>
      {/* Header */}
      <Stack
        direction="column"
        alignItems="flex-start"
        width={1}
        pt={4}
        sx={(theme) => ({
          bgcolor: theme.palette.primary.main,
        })}
      >
        <Stack width={200} sx={{ px: { xs: 4, sm: 8 } }}>
          <a href={BLUESYSTEMS_URL}>
            <img src="/logo_bluesystems.svg" alt="bluesystems logo" />
          </a>
        </Stack>
        <Stack width={1} sx={{ height: { xs: 50, sm: 100, md: 200 } }}>
          <svg viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon fill="white" points="100,0 100,100 0,100" />
          </svg>
        </Stack>
      </Stack>

      <Stack direction="column" alignItems="center" width={1} gap={8} mb={4} sx={{ width: { xs: '90%', md: '80%' } }}>
        <Stack direction="column" alignItems="center" gap={4}>
          <Stack direction="row" alignItems="center" sx={{ gap: { xs: 2, md: 4 } }}>
            {/* <PolicyIcon
                            sx={(theme: any) => ({
                                fontSize: 40,
                                color: theme.palette.primary.main,
                            })}
                        /> */}
            <Typography textAlign="center" variant="h2" sx={(theme) => ({ color: theme.palette.primary.main })}>
              {t('data_privacy.title1')}
            </Typography>
          </Stack>
          <Typography
            variant="h4"
            sx={(theme) => ({
              wordWrap: 'break-word',
              whiteSpace: 'pre-line',
              p: 2,
              borderStyle: 'solid',
              borderColor: theme.palette.primary.main,
              borderRadius: 4,
            })}
          >
            {t('data_privacy.privacy_standards')}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-around" flexWrap="wrap" height={75} width={1}>
          <img src="/certification.png" alt="certification" style={{ height: 'inherit' }} />
          <img src="/certification.png" alt="certification" style={{ height: 'inherit' }} />
          <img src="/certification.png" alt="certification" style={{ height: 'inherit' }} />
        </Stack>
        <Stack direction="column" alignItems="center" gap={4}>
          <Typography textAlign="center" variant="h2" sx={(theme) => ({ color: theme.palette.primary.main })}>
            {t('data_privacy.title2')}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              width: { xs: '100%', md: '80%' },
              gap: { xs: 4, md: 8 },
            }}
          >
            <img src="/veo_logo.png" alt="comment1 logo" style={{ height: 100 }} />
            <Typography
              variant="h4"
              sx={(theme) => ({
                wordWrap: 'break-word',
                whiteSpace: 'pre-line',
                p: 2,
                borderStyle: 'solid',
                borderColor: theme.palette.primary.main,
                borderRadius: 4,
              })}
            >
              {t('data_privacy.comment1')}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              width: { xs: '100%', md: '80%' },
              gap: { xs: 4, md: 8 },
            }}
          >
            <Typography
              variant="h4"
              sx={(theme) => ({
                wordWrap: 'break-word',
                whiteSpace: 'pre-line',
                p: 2,
                borderStyle: 'solid',
                borderColor: theme.palette.primary.main,
                borderRadius: 4,
              })}
            >
              {t('data_privacy.comment2')}
            </Typography>
            <img src="/bird_logo.png" alt="comment2 logo" style={{ height: 100 }} />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              width: { xs: '100%', md: '80%' },
              gap: { xs: 4, md: 8 },
            }}
          >
            <img src="/spin_logo.png" alt="comment3 logo" style={{ height: 100 }} />
            <Typography
              variant="h4"
              sx={(theme) => ({
                wordWrap: 'break-word',
                whiteSpace: 'pre-line',
                p: 2,
                borderStyle: 'solid',
                borderColor: theme.palette.primary.main,
                borderRadius: 4,
              })}
            >
              {t('data_privacy.comment3')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Body */}
      {/* <Stack direction="column" alignItems="center" gap={8} px={4} mb={4}>
                <Stack direction="column" alignItems="center" gap={4}>
                    <Stack direction="row" alignItems="center" gap={4}>
                        <PolicyIcon
                            sx={(theme: any) => ({
                                fontSize: 40,
                                color: theme.palette.primary.main,
                            })}
                        />
                        <Typography textAlign="center" variant='h2' sx={(theme) => ({ color: theme.palette.primary.main })}>
                            {t('data_privacy.title1')}
                        </Typography>
                    </Stack>
                    <Stack sx={{ width: { xs: '100%', md: '80%' } }}>
                        <Typography
                            variant="h4"
                            sx={(theme) => ({
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-line',
                                p: 2,
                                borderStyle: 'solid',
                                borderColor: theme.palette.primary.main,
                                borderRadius: 4,
                            })}
                        >
                            {t('data_privacy.privacy_standards')}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-around"
                    flexWrap="wrap"
                    height={75}
                    sx={{
                        width: { xs: '100%', md: '80%' },
                    }}
                >
                    <img src="/certification.png" alt="certification" style={{ height: 'inherit' }} />
                    <img src="/certification.png" alt="certification" style={{ height: 'inherit' }} />
                    <img src="/certification.png" alt="certification" style={{ height: 'inherit' }} />
                </Stack>
                <Stack direction="column" alignItems="center" gap={4}>
                    <Typography textAlign="center" variant='h2' sx={(theme) => ({ color: theme.palette.primary.main })}>
                        {t('data_privacy.title2')}
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            width: { xs: '100%', md: '80%' },
                            gap: { xs: 4, md: 8},
                        }}
                    >
                        <img src="/veo_logo.png" alt="comment1 logo" style={{ height: 100 }} />
                        <Typography
                            variant="h4"
                            sx={(theme) => ({
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-line',
                                p: 2,
                                borderStyle: 'solid',
                                borderColor: theme.palette.primary.main,
                                borderRadius: 4,
                            })}
                        >
                            {t('data_privacy.comment1')}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            width: { xs: '100%', md: '80%' },
                            gap: { xs: 4, md: 8},
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={(theme) => ({
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-line',
                                p: 2,
                                borderStyle: 'solid',
                                borderColor: theme.palette.primary.main,
                                borderRadius: 4,
                            })}
                        >
                            {t('data_privacy.comment2')}
                        </Typography>
                        <img src="/bird_logo.png" alt="comment2 logo" style={{ height: 100 }} />
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            width: { xs: '100%', md: '80%' },
                            gap: { xs: 4, md: 8},
                        }}
                    >
                        <img src="/spin_logo.png" alt="comment3 logo" style={{ height: 100 }} />
                        <Typography
                            variant="h4"
                            sx={(theme) => ({
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-line',
                                p: 2,
                                borderStyle: 'solid',
                                borderColor: theme.palette.primary.main,
                                borderRadius: 4,
                            })}
                        >
                            {t('data_privacy.comment3')}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack> */}
    </Stack>
  )
}

export default DataPrivacy
