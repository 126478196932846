import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { withStyles, createStyles, WithStyles } from '@mui/styles'
import MapBoxGL, { AnyLayer, MapboxOptions, Marker, NavigationControl, ScaleControl } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import React, { useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useTranslation } from 'react-i18next'

import { Theme } from 'Theme/theme'

import { IGeoJsonData, IViewport, MapContext, MapEvent } from './index'

const dev_mapbox_token = 'pk.eyJ1IjoiaGpvdWhhdWQiLCJhIjoiY2xkaXp5bjMxMDhuMTN2bnpoNnIybm40dSJ9.BI2TDBSJNxOexT417Gwziw'

class ComponentControl {
  map: MapBoxGL.Map | undefined
  container: HTMLElement
  component: React.ReactElement

  constructor(component: React.ReactElement) {
    this.component = component
    this.container = document.createElement('div')
  }

  onAdd(map: MapBoxGL.Map) {
    this.map = map
    createRoot(this.container).render(this.component)
    return this.container
  }
  onRemove(): void {
    this.container?.parentNode?.removeChild(this.container)
    this.map = undefined
  }
}
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      borderTopLeftRadius: 'inherit',
    },
    map: {},
    button: {
      width: '100%',
      margin: `0px ${theme.spacing()}px`,
    },
  })

const defaultOpts: MapBoxGL.MapboxOptions = {
  minZoom: 0,
  maxZoom: 20,
  hash: false,
  container: '',
  preserveDrawingBuffer: false,
  scrollZoom: true,
  interactive: true,
  dragRotate: true,
  attributionControl: true,
  logoPosition: 'bottom-left',
  renderWorldCopies: true,
  trackResize: true,
  touchZoomRotate: true,
  doubleClickZoom: true,
  keyboard: true,
  dragPan: true,
  boxZoom: true,
  refreshExpiredTiles: true,
  failIfMajorPerformanceCaveat: false,
  bearingSnap: 7,
  style: 'mapbox://styles/mapbox/light-v9',
}

const useMap = (
  token: string,
  opts: Partial<MapBoxGL.MapboxOptions>,
  customAction?: React.ReactElement
): { initMap: (c: HTMLElement, o: MapBoxGL.MapboxOptions) => void; map: MapBoxGL.Map | undefined; ready: boolean } => {
  const [map, setMap] = useState<MapBoxGL.Map | undefined>(undefined)

  const [mapOptions] = useState<MapBoxGL.MapboxOptions>({
    ...defaultOpts,
    ...opts,
  })
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    if (token) {
      MapBoxGL.accessToken = token
    }
    return () => {
      if (map) {
        map.remove()
        setMap(undefined)
      }
    }
  }, [map, token])

  const initMap = (container: HTMLElement, options: MapBoxGL.MapboxOptions) => {
    const newMap = new MapBoxGL.Map({ ...mapOptions, ...options, container })

    // This seems to be needed in mapbox v1 for the MapboxGeocoder's marker to work
    // otherwise, it fails with "Uncaught TypeError: this._mapboxgl.Marker is not a constructor"
    Object.assign(newMap, { Marker: Marker })

    // add zoom controls
    newMap.addControl(new NavigationControl({ showZoom: true, showCompass: false }), 'bottom-right')

    if (customAction) {
      newMap.addControl(new ComponentControl(customAction), 'bottom-right')
    }

    newMap.on('load', () => {
      setReady(true)
      setMap(newMap)
    })
  }

  return { map, initMap, ready }
}

export interface IMapProps extends WithStyles<typeof styles> {
  children?: React.ReactNode
  currentGeoJSON?: IGeoJsonData
  customAction?: React.ReactElement
  height?: number
  onBoxZoomCancel?: MapEvent
  onBoxZoomEnd?: MapEvent
  onBoxZoomStart?: MapEvent
  onClick?: MapEvent
  onContextMenu?: MapEvent
  onData?: MapEvent
  onDataLoading?: MapEvent
  onDblClick?: MapEvent
  onDrag?: MapEvent
  onDragEnd?: MapEvent
  onDragStart?: MapEvent
  onError?: MapEvent
  onMapReady?: (map: MapBoxGL.Map) => void
  onMouseDown?: MapEvent
  onMouseMove?: MapEvent
  onMouseOut?: MapEvent
  onMouseUp?: MapEvent
  onMove?: MapEvent
  onMoveEnd?: MapEvent
  onMoveStart?: MapEvent
  onPitch?: MapEvent
  onPitchEnd?: MapEvent
  onPitchStart?: MapEvent
  onRemove?: MapEvent
  onRender?: MapEvent
  onResize?: MapEvent
  onRotate?: MapEvent
  onRotateEnd?: MapEvent
  onRotateStart?: MapEvent
  onSourceData?: MapEvent
  onSourceDataLoading?: MapEvent
  onStyleData?: MapEvent
  onStyleDataLoading?: MapEvent
  onStyleLoad?: MapEvent
  onTouchCancel?: MapEvent
  onTouchEnd?: MapEvent
  onTouchMove?: MapEvent
  onTouchStart?: MapEvent
  onViewportChange?: (position: IViewport) => void
  onWebGlContextLost?: MapEvent
  onWebGlContextRestored?: MapEvent
  onZoom?: MapEvent
  onZoomEnd?: MapEvent
  onZoomStart?: MapEvent
  options?: Partial<MapboxOptions>
  showSatellite?: boolean
  showScale?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | false
  showSearchBar?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | false
  viewport?: IViewport
  width?: number
}

const MapBoxMap = (props: IMapProps) => {
  const {
    classes,
    children,
    height,
    width,
    viewport,
    showScale = false,
    showSearchBar = false,
    onMapReady,
    options = {},
  } = props
  const { t } = useTranslation()

  const { map, initMap, ready } = useMap(dev_mapbox_token, options, props.customAction)
  // const { distance_unit } = useAgencySettings()
  const container = useRef<HTMLDivElement | null>(null)

  const loadBaseLayers = (): void => {
    if (!map) {
      return
    }

    if (showSearchBar) {
      const geocoder = new MapboxGeocoder({
        accessToken: dev_mapbox_token,
        marker: false,
      })
      map.addControl(geocoder, showSearchBar)
    }

    if (showScale) {
      const unit = 'metric' // distance_unit === 'miles' ? 'imperial' : 'metric'
      map.addControl(new ScaleControl({ unit: unit }), showScale)
    }
  }

  // Init map while container is available
  useEffect(() => {
    const currentContainer = container.current
    if (currentContainer) {
      initMap(currentContainer, {
        ...viewport,
        container: currentContainer,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container])

  useEffect(() => {
    if (viewport && map) {
      map.setZoom(viewport.zoom)
      map.setCenter({
        lat: viewport.center.lat,
        lng: viewport.center.lng,
      })
    }
  }, [map, viewport])

  useEffect(() => {
    if (ready && map) {
      if (onMapReady) {
        loadBaseLayers()
        onMapReady(map)

        const { layers = [] } = (map && map.getStyle()) || { layers: [] }
        layers.forEach((anyLayer: AnyLayer) => {
          const layer = anyLayer as MapBoxGL.FillLayer | MapBoxGL.LineLayer | MapBoxGL.SymbolLayer

          if (layer.type === 'symbol') {
            map.setLayoutProperty(layer.id, 'text-field', ['get', t('language.map')])
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, ready])

  /** On container resize */
  useEffect(() => {
    if (ready && map) {
      map.resize()
    }
  }, [props.width, props.height, ready, map])

  return (
    <MapContext.Provider value={map}>
      <div style={{ height, width }} ref={container} className={classes.map}>
        {children}
      </div>
    </MapContext.Provider>
  )
}

export default withStyles(styles)(MapBoxMap)
