import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DistanceUnit } from './constants'

interface IDistanceUnitsProps {
  distanceUnit: DistanceUnit
  onChange: (newDistanceUnit: DistanceUnit) => void
}

export const DistanceUnits = function (props: IDistanceUnitsProps) {
  const { t } = useTranslation()

  return (
    <FormControl sx={{ top: { xs: '2rem' }, mx: 3 }}>
      <FormLabel>{t('Distance unit')}</FormLabel>
      <RadioGroup
        value={props.distanceUnit}
        onChange={(event) => props.onChange(event.target.value as DistanceUnit)}
        row
      >
        <FormControlLabel value={DistanceUnit.km} control={<Radio />} label={t('km')} />
        <FormControlLabel value={DistanceUnit.miles} control={<Radio />} label={t('miles')} />
      </RadioGroup>
    </FormControl>
  )
}
