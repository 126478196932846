import { createTheme } from '@mui/material/styles'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } })

const blueHex1 = '#0171e4'

const paletteTheme = {
  palette: {
    primary: {
      main: '#3a75c4',
      light: '#2C95DE',
    },
    background: {
      default: '#FFFFFF',
    },
    grey: {
      100: '#F2F2F2',
    },
    blues: {
      main: '#FFFFFF',
      blue1: blueHex1,
    },
    blue1: createColor(blueHex1),
  },
}

export default paletteTheme
