import isEqual from 'lodash/isEqual'
import { DependencyList, useEffect, useRef } from 'react'

function useDeepCompareMemoize(value: DependencyList) {
  const ref = useRef<DependencyList>()
  if (!isEqual(value, ref.current)) {
    ref.current = value
  }
  return ref.current
}

function useDeepCompareEffect(callback: () => void, dependencies: unknown[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

export default useDeepCompareEffect
