import { Stack } from '@mui/material'
import React from 'react'

import { DistanceUnit } from 'Components/constants'
import { DistanceUnits } from 'Components/DistanceUnits'
import { IMetaData } from 'Components/FetchData'

import { BlueSystemsLogo } from './BlueSystemsLogo'
import { HeaderBreadcrumbs } from './HeaderBreadcrumbs'

interface IHeaderProps {
  distanceUnit: DistanceUnit
  metaData: IMetaData
  setDistanceUnit: (distanceUnit: DistanceUnit) => void
}

export const Header = function (props: IHeaderProps) {
  return (
    <Stack
      direction={'column'}
      alignItems={'flex-start'}
      width={1}
      pt={4}
      sx={(theme) => ({
        bgcolor: theme.palette.primary.main,
      })}
    >
      <BlueSystemsLogo />
      <HeaderBreadcrumbs
        logo={{
          path: `/${props.metaData.logoPath}`,
          name: props.metaData.name.replace(/[^a-zA-Z0-9 ]/g, ''),
        }}
      />
      <Stack
        width={1}
        sx={{
          // 0.3% of transition to smooth the separation (think antialiasing)
          background: 'linear-gradient(to bottom right, transparent 0%, transparent 50%, white 50.4%, white 100%)',
          paddingY: '2rem',
        }}
        direction={'row-reverse'}
        alignItems={'center'}
      >
        <DistanceUnits distanceUnit={props.distanceUnit} onChange={props.setDistanceUnit} />
      </Stack>
    </Stack>
  )
}
