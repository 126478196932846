import { alpha, Stack, Typography, useTheme } from '@mui/material'
import {
  BarElement,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
  ChartDataset,
} from 'chart.js'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import formatNumber from './formatNumber'

// import { buildHistogramOptions, getLabelsWithGranularity, IGraphProps, STYLE_CHART_CONTAINER } from './graphCommons'
// import { DEFAULT_DATASET_STYLES } from './themes'

ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const getDocCount = (bucket: IGenericBucket): number => bucket.doc_count ?? 0

export enum GranularityEnum {
  day = 'day',
  hour = 'hour',
  month = 'month',
  quarter = 'quarter',
  week = 'week',
}

export interface IGenericBucket {
  doc_count?: number
  key?: string
}

interface ITemporalHistogramProps {
  granularity: GranularityEnum
  percentage?: boolean
  rawData: IGenericBucket[]
  title: string
  total?: boolean
}

const getFormat = (granularity: GranularityEnum, t: ReturnType<typeof useTranslation>['t']) => {
  switch (granularity) {
    case 'quarter':
      return t('date_format.quarter')
    case 'month':
      return t('date_format.month')
    case 'week':
      return t('date_format.week')
    case 'day':
      return t('date_format.day')
    case 'hour':
      return t('date_format.hour')
    default:
      return t('date_format.day')
  }
}

function getLabelsWithGranularity(
  buckets: IGenericBucket[],
  granularity: GranularityEnum,
  t: ReturnType<typeof useTranslation>['t']
): string[] {
  return buckets.map((b) => {
    return moment(b.key).format(getFormat(granularity, t))
  })
}

function TemporalHistogram(props: ITemporalHistogramProps) {
  const { title, total, rawData, granularity, percentage } = props
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  const datasets: ChartDataset<'bar', number[]>[] = [
    {
      backgroundColor: alpha(theme.palette.primary.main ?? '', 0.2),
      hoverBackgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      label: '',
      data: rawData.map(getDocCount),
    },
  ]
  const labels: string | string[] = getLabelsWithGranularity(rawData, granularity, t)

  const options = {
    plugins: {
      legend: { display: false },
      title: {
        display: total,
        text: total ? `Total ${formatNumber(i18n.language, _.sum(rawData.map((data) => data.doc_count)))}` : undefined,
      },
      tooltip: percentage
        ? {
            callbacks: {
              label: (context: TooltipItem<'bar'>) => {
                let label = context.dataset.label || ''
                if (context.parsed.y !== null) {
                  label += ' ' + context.parsed.y + '%'
                }
                return label
              },
            },
          }
        : undefined,
    },
    scales: percentage
      ? {
          y: {
            ticks: {
              callback: (value: number | string) => value + ' %',
            },
          },
        }
      : undefined,
  }

  return (
    <Stack direction="column" alignItems="center" sx={{ width: '100%' }}>
      <Typography textAlign="center" variant="h2" sx={(theme) => ({ color: theme.palette.primary.main })}>
        {title}
      </Typography>
      <Bar data={{ datasets, labels }} options={options} />
    </Stack>
  )
}

export default TemporalHistogram
