import {
  Card,
  LinearProgress,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import React, { useMemo, useTransition } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { useLocalStorage } from 'hooks/useLocalStorage'

import { DistanceUnit, LocalStorageKey } from './constants'
import { DistanceUnits } from './DistanceUnits'
import { fetchTableData } from './FetchData'
import formatDate from './formatDate'
import SharedMobilityTableFormatCell from './SharedMobilityTableFormatCell'

export enum accessorSharedMobilityTableEnum {
  CITY,
  TOTAL_TRIPS,
  TOTAL_DISTANCE,
  MAX_VEHICLES_DEPLOYED,
}

interface ISharedMobilityTableProps {
  sxs?: SxProps<Theme>
}

function SharedMobilityTable(props: ISharedMobilityTableProps) {
  const { sxs } = props
  const { t, i18n } = useTranslation()
  const [, startTransition] = useTransition()
  const [distanceUnit, setDistanceUnit] = useLocalStorage<DistanceUnit>(LocalStorageKey.DISTANCE_UNIT, DistanceUnit.km)

  const { data: tableData, isLoading } = useQuery({
    queryKey: ['fetchTableData', distanceUnit],
    queryFn: () => fetchTableData(distanceUnit),
    keepPreviousData: true,
  })

  const headers = useMemo<{ accessor: accessorSharedMobilityTableEnum; label: string }[]>(
    () => [
      {
        label: t('home_page.shared_mobility_table.city'),
        accessor: accessorSharedMobilityTableEnum.CITY,
      },
      {
        label: t('home_page.shared_mobility_table.total_trips'),
        accessor: accessorSharedMobilityTableEnum.TOTAL_TRIPS,
      },
      {
        label: t('home_page.shared_mobility_table.total_distance'),
        accessor: accessorSharedMobilityTableEnum.TOTAL_DISTANCE,
      },
      {
        label: t('home_page.shared_mobility_table.max_vehicles_deployed'),
        accessor: accessorSharedMobilityTableEnum.MAX_VEHICLES_DEPLOYED,
      },
    ],
    [t]
  )

  return (
    <Card sx={sxs}>
      <Stack direction="column">
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction="column" p={2}>
            <Typography variant="h3">{t('home_page.shared_mobility_table.title')}</Typography>
            <Typography variant="h5">
              <Trans
                i18nKey="home_page.shared_mobility_table.helper"
                values={{
                  from: formatDate(i18n.language, tableData?.from),
                  to: formatDate(i18n.language, tableData?.to),
                }}
                tOptions={{
                  interpolation: { escapeValue: false },
                }}
              />
            </Typography>
          </Stack>
          <DistanceUnits
            distanceUnit={distanceUnit}
            onChange={(distanceUnit: DistanceUnit) => startTransition(() => setDistanceUnit(distanceUnit))}
          />
        </Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={`header-${header.accessor}`}>
                    <Typography variant="h4" fontWeight="bold">
                      {header.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.table.map((data) => (
                <TableRow key={`row-${data.name}`}>
                  {headers.map((header) => (
                    <TableCell key={`cell-${data.name}-${header.accessor}`}>
                      <SharedMobilityTableFormatCell
                        key={`formatCell-${data.name}-${header.accessor}`}
                        accessor={header.accessor}
                        data={{
                          cityName: data.name,
                          totalTrips: data.total_trips,
                          totalDistance: data.total_distance,
                          maxVehicles: data.max_vehicles_deployed,
                        }}
                        distanceUnit={distanceUnit}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {isLoading && <LinearProgress />}
    </Card>
  )
}

export default SharedMobilityTable
